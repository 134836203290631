import {
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    height: undefined,
    width: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export function useForceUpdate() {
  const [, forceUpdate] = useState<boolean>();

  return useCallback(() => {
    forceUpdate((s) => !s);
  }, []);
}

export function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

// export const useChartDimensions = () => useContext(ChartContext);

export type Dimensions = {
  boundedHeight?: number;
  boundedWidth?: number;
  height?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  tickLabelOffset?: number;
  width?: number;
};

export const combineChartDimensions = (dimensions: Dimensions) => {
  const parsedDimensions = {
    marginBottom: 32,
    marginLeft: 58,
    marginRight: 30,
    marginTop: 32,
    tickLabelOffset: 0,
    ...dimensions,
  };

  return {
    ...parsedDimensions,
    boundedHeight: Math.max(
      parsedDimensions.height -
        parsedDimensions.marginTop -
        parsedDimensions.marginBottom,
      0
    ),
    boundedWidth: Math.max(
      parsedDimensions.width -
        parsedDimensions.marginLeft -
        parsedDimensions.marginRight,
      0
    ),
  };
};

export const useChartDimensions = (
  passedSettings?: Dimensions
): [MutableRefObject<HTMLDivElement>, Dimensions] => {
  const ref = useRef();
  const dimensions = combineChartDimensions(passedSettings);

  const [width, changeWidth] = useState(0);
  const [height, changeHeight] = useState(0);

  useEffect(() => {
    if (dimensions.width && dimensions.height) return;

    const element = ref.current;
    const resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries)) return;
      if (!entries.length) return;

      const entry = entries[0];

      if (width !== entry.contentRect.width)
        changeWidth(entry.contentRect.width);
      if (height !== entry.contentRect.height)
        changeHeight(entry.contentRect.height);
    });

    resizeObserver.observe(element);

    return () => resizeObserver.unobserve(element);
  }, [passedSettings, height, width, dimensions]);

  const newSettings = combineChartDimensions({
    ...dimensions,
    height: dimensions.height || height,
    width: (dimensions.width < width ? width : dimensions.width) || width,
  });

  return [ref, newSettings];
};

let lastId = 0;
export const useUniqueId = (prefix = '') => {
  lastId += 1;
  return [prefix, lastId].join('-');
};

export function useShallowEqualSelector(selector) {
  return useSelector(selector, shallowEqual);
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export function useImage(url: string, crossOrigin?: string) {
  const statusRef = useRef('loading');
  const imageRef = useRef<HTMLImageElement>();

  const [, setStateToken] = useState(0);

  const oldUrl = useRef<string>();
  const oldCrossOrigin = useRef<string>();
  if (oldUrl.current !== url || oldCrossOrigin.current !== crossOrigin) {
    statusRef.current = 'loading';
    imageRef.current = undefined;
    oldUrl.current = url;
    oldCrossOrigin.current = crossOrigin;
  }

  useLayoutEffect(() => {
    if (!url) return () => {};

    const img = document.createElement('img');

    function onload() {
      statusRef.current = 'loaded';
      imageRef.current = img;
      setStateToken(Math.random());
    }

    function onerror() {
      statusRef.current = 'failed';
      imageRef.current = undefined;
      setStateToken(Math.random());
    }

    img.addEventListener('load', onload);
    img.addEventListener('error', onerror);
    if (crossOrigin) img.crossOrigin = crossOrigin;
    img.src = url;

    return () => {
      img.removeEventListener('load', onload);
      img.removeEventListener('error', onerror);
    };
  }, [url, crossOrigin]);

  return [imageRef.current, statusRef.current];
}
