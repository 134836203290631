import {
  CANCEL_SUBSCRIPTION_FAILURE,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CREATE_ORGANIZATION_FAILURE,
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_FAILURE,
  DELETE_ORGANIZATION_REQUEST,
  DELETE_ORGANIZATION_SUCCESS,
  LIST_ORGANIZATIONS_FAILURE,
  LIST_ORGANIZATIONS_REQUEST,
  LIST_ORGANIZATIONS_SUCCESS,
  SET_ACTIVE_ORGANIZATION,
  UPDATE_ORGANIZATION_FAILURE,
  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_SUCCESS,
} from '../constants/action-types';
import { IIndustry } from '../types/api/industry';
import { IOrganization } from '../types/api/organization';

export const setActiveOrganization = (organization: IOrganization) => ({
  organization,
  type: SET_ACTIVE_ORGANIZATION,
});

export const listOrganizationsRequest = () => ({
  type: LIST_ORGANIZATIONS_REQUEST,
});

export const listOrganizationsSuccess = (organizations: IOrganization[]) => ({
  organizations,
  type: LIST_ORGANIZATIONS_SUCCESS,
});

export const listOrganizationsFailure = (error: unknown) => ({
  error,
  type: LIST_ORGANIZATIONS_FAILURE,
});

export const updateOrganizationRequest = (
  payload: Partial<Omit<IOrganization, 'industries'>> & {
    industries?: IIndustry['id'][];
  }
) => ({
  payload,
  type: UPDATE_ORGANIZATION_REQUEST,
});

export const updateOrganizationSuccess = (
  payload: Partial<IOrganization>,
  id: IOrganization['id']
) => ({
  id,
  payload,
  type: UPDATE_ORGANIZATION_SUCCESS,
});

export const updateOrganizationFailure = (
  error: unknown,
  validation: Validation[]
) => ({
  error,
  type: UPDATE_ORGANIZATION_FAILURE,
  validation,
});

export const deleteOrganizationRequest = (id: IOrganization['id']) => ({
  id,
  type: DELETE_ORGANIZATION_REQUEST,
});

export const deleteOrganizationSuccess = (id: IOrganization['id']) => ({
  id,
  type: DELETE_ORGANIZATION_SUCCESS,
});

export const deleteOrganizationFailure = (error: unknown) => ({
  error,
  type: DELETE_ORGANIZATION_FAILURE,
});

export const cancelSubscriptionRequest = (id: IOrganization['id']) => ({
  id,
  type: CANCEL_SUBSCRIPTION_REQUEST,
});

export const cancelSubscriptionSuccess = (id: IOrganization['id']) => ({
  id,
  type: CANCEL_SUBSCRIPTION_SUCCESS,
});

export const cancelSubscriptionFailure = (error: unknown) => ({
  error,
  type: CANCEL_SUBSCRIPTION_FAILURE,
});

export const createOrganizationRequest = (payload: Partial<IOrganization>) => ({
  payload,
  type: CREATE_ORGANIZATION_REQUEST,
});

export const createOrganizationSuccess = (organization: IOrganization) => ({
  organization,
  type: CREATE_ORGANIZATION_SUCCESS,
});

export const createOrganizationFailure = (error: unknown) => ({
  error,
  type: CREATE_ORGANIZATION_FAILURE,
});
