import { call, put, select, takeLatest } from 'redux-saga/effects';

import { hideFoldout } from '../actions/foldouts';
import { hideModal } from '../actions/modals';
import {
  cancelSubscriptionFailure,
  cancelSubscriptionRequest,
  cancelSubscriptionSuccess,
  createOrganizationFailure,
  createOrganizationRequest,
  createOrganizationSuccess,
  deleteOrganizationFailure,
  deleteOrganizationRequest,
  deleteOrganizationSuccess,
  listOrganizationsFailure,
  listOrganizationsSuccess,
  setActiveOrganization,
  updateOrganizationFailure,
  updateOrganizationRequest,
  updateOrganizationSuccess,
} from '../actions/organizations';
import { setSelectedOrganization } from '../actions/segmentation';
import { Organizations } from '../api';
import {
  CANCEL_SUBSCRIPTION_REQUEST,
  CREATE_ORGANIZATION_REQUEST,
  DELETE_ORGANIZATION_REQUEST,
  LIST_ORGANIZATIONS_REQUEST,
  UPDATE_ORGANIZATION_REQUEST,
} from '../constants/action-types';
import { ORGANIZATION_SIZES } from '../constants/organization';
import { industriesSelector } from '../selectors/industry';
import { activeOrganizationSelector } from '../selectors/organizations';
import { selectedOrganizationSelector } from '../selectors/segmentation';
import { IIndustry } from '../types/api/industry';
import { IOrganization } from '../types/api/organization';
import { parseError } from '../utils/error';

export function* listOrganizations() {
  try {
    const { body } = yield call(Organizations.List);

    const organizations: IOrganization[] = body;

    yield put(listOrganizationsSuccess(organizations));

    const selectedOrganization = yield select(selectedOrganizationSelector);

    const activeOrganization = yield select(activeOrganizationSelector);

    const exist = organizations.some(
      (organization) =>
        selectedOrganization && organization.id === selectedOrganization.value
    );

    const exist2 = organizations.some(
      (organization) =>
        activeOrganization && organization.id === activeOrganization.id
    );

    if (!exist || !selectedOrganization) {
      yield put(
        setSelectedOrganization(
          organizations.length > 0
            ? {
                label: organizations[0].short_name,
                value: organizations[0].id.toString(),
              }
            : null
        )
      );
    }

    if (!exist2 || !activeOrganization) {
      yield put(setActiveOrganization(organizations[0]));
    }
  } catch (e) {
    yield put(listOrganizationsFailure(e));
  }
}

export function* updateOrganization({
  payload,
}: ReturnType<typeof updateOrganizationRequest>) {
  try {
    const params = structuredClone(payload);

    const industries: IIndustry[] = yield select(industriesSelector);

    // TODO: This logic should be moved to BE.

    const { id: organizationId, ...rest } = yield select(
      activeOrganizationSelector
    );

    yield call(Organizations.Patch, organizationId, params);
    yield put(
      updateOrganizationSuccess(
        {
          ...payload,
          industries: params.industries
            ? params.industries.map((id) => {
                const industry = industries.find((i) => i.id == id);
                return {
                  id: industry.id,
                  key: industry.key,
                  type: industry.type,
                };
              })
            : rest.industries,
          locations_amount: params.locations_amount
            ? ORGANIZATION_SIZES[params.locations_amount]
            : rest.locations_amount,
        },
        organizationId
      )
    );
  } catch (e) {
    yield put(updateOrganizationFailure(e, parseError(e)));
  }
}

export function* deleteOrganization({
  id,
}: ReturnType<typeof deleteOrganizationRequest>) {
  try {
    yield call(Organizations.Delete, id);
    yield put(deleteOrganizationSuccess(id));
    yield put(hideModal());
  } catch (e) {
    yield put(deleteOrganizationFailure(e));
  }
}

export function* cancelSubscription({
  id,
}: ReturnType<typeof cancelSubscriptionRequest>) {
  try {
    yield call(Organizations.Cancel, id);
    yield put(cancelSubscriptionSuccess(id));
    yield put(hideModal());
  } catch (e) {
    yield put(cancelSubscriptionFailure(e));
  }
}

export function* createOrganization({
  payload,
}: ReturnType<typeof createOrganizationRequest>) {
  try {
    const { body: organization } = yield call(Organizations.Create, payload);
    yield put(createOrganizationSuccess(organization));
    yield put(hideFoldout());
  } catch (e) {
    yield put(createOrganizationFailure(e));
  }
}

export function* watchOrganizations() {
  yield takeLatest(LIST_ORGANIZATIONS_REQUEST, listOrganizations);
  yield takeLatest(UPDATE_ORGANIZATION_REQUEST, updateOrganization);
  yield takeLatest(DELETE_ORGANIZATION_REQUEST, deleteOrganization);
  yield takeLatest(CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription);
  yield takeLatest(CREATE_ORGANIZATION_REQUEST, createOrganization);
}
